import { mapActions, mapState } from "vuex";
import ValidationAlert from "@/components/global/ValidationAlert.vue";

export default {
  name: "UpdateAreaDialog",
  data() {
    return {
      listError: [],
      valid: false,
      loadingSave: false,
      areaForm: {
        name: "",
        responsible_id: null,
      },
      nameRules: [
        (v) => !!v || "Nombre de Área es requerido",
        (v) => v.length <= 50 || "El nombre debe tener menos de 50 carácteres",
      ],
      responsablesRules:[
				(v) => !!v || "Responsable es requerido",
			]
    };
  },
  props: {
    value: Boolean,
    item: Object,
  },
  watch: {
		dialog(newVal) {
      if(newVal){
        this.areaForm = {
          name: this.item.name,
          responsible_id: this.item?.responsible_id?.id || null,
        }
      }
		},
	},
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return this.item ? 'Edit Area' : 'New Area';
    },
    ...mapState('user',['responsibles']),
  },
  methods: {
    ...mapActions("area", ["updateArea","listArea"]),
    // ...mapActions("security", ["getUsersActive"]),
    close() {
      this.dialog = false;
      this.areaForm = { name: "", company_id: null };
      this.listError = [];
      this.$refs.form.resetValidation();
    },
    async save() {
   
      if (!this.$refs?.form?.validate()) {
        return;
      }
      
      this.listError = [];
      const data = {
        ...this.areaForm,
        company_id: parseInt(localStorage.getItem("company_id")),
      };
      this.loadingSave = true;
      const {ok, error} = await this.updateArea({id: this.item.id, data:data});
      if(ok){
        this.dialog = false;
        this.$emit("getArea");
        this.areaForm = {name:"",company_id:""};
        this.$refs?.form?.resetValidation();
      }else{
        if (error === "Esta área ya existe para la empresa seleccionada") {
          this.listError.push(
            "Ya existe un área con ese nombre para la misma compañía."
          );
        } else {
          this.listError = Array.isArray(error?.data?.message) ? error?.data?.message : [error?.data?.message] || [];
        }
      }
      this.loadingSave = false;
    },
    dialogInput(value) {
      this.dialog = value;
      if (!value) {
        this.$nextTick(() => {
          this.areaForm = { name: "", company_id: null };
          this.listError = [];
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  components:{
    ValidationAlert,
  }
};