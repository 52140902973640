import CreateAreaDialog from '../../elements/CreateAreaDialog';
import UpdateAreaDialog from '../../elements/UpdateAreaDialog';
import DeleteAreaDialog from '../../elements/DeleteAreaDialog';
import ImportMassiveAreasDialog from '../../elements/ImportMassiveAreasDialog';
import ValidateActivity from '@/components/global/ValidateActivity.vue';
import { mapState, mapActions } from 'vuex';
import { showError } from '@/helpers/globalHelpers';

export default {
	name: 'AreaTab',
	data() {
		return {
			loading: false,
			dialog: false,
			headers: [
				{
					text: 'Nombre de Área',
					align: 'start',
					sortable: false,
					value: 'name',
				},
				{
					text: 'Responsable',
					align: 'start',
					sortable: false,
					value: 'responsible_id.fullName',
				},
				{
					text: 'Acciones',
					align: 'start',
					sortable: false,
					value: 'actions',
				},
			],
			editDialog: false,
			deleteDialog: false,
			editedItem: {},
			limitOptions: [5, 10, 20, 50],
			filters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
				pagination: true,
				//q: '',
			},
		};
	},
	computed: {
		...mapState('area', ['areas', 'areasPagination']),
	},
	created() {
		this.getArea();
		this.getResponsibles();
	},
	methods: {
		...mapActions('area', ['listArea', 'cleanAreas']),
		...mapActions('user',['getResponsibles']),
		editItem(item) {
			this.editedItem = Object.assign({}, item);
			this.editDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		deleteItem(item) {
			this.editedItem = Object.assign({}, item);
			this.deleteDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		async closeDeleteDialog() {
			if (this.areas.length == 1 && this.filters.page > 1) {
				this.filters.page = this.filters.page - 1;
			}
			await this.getArea();
		},

		//
		async closeCreateDialog() {
			this.filters.page = 1;
			await this.getArea();
		},
		//

		async getArea() {
			this.loading = true;
			const { error } = await this.listArea(this.filters);
			if (error) showError(error);
			this.loading = false;
		},
		updatePageAfterDelete(totalItems) {
			const totalPages = Math.ceil(totalItems / this.areas.limit);
			if (this.areas.page > totalPages) {
				this.$store.commit('area/SET_PAGE', totalPages);
				this.listArea({ page: totalPages, limit: this.areas.limit });
			}
		},
	},
	watch: {
		'areas.totalItems': function (newTotalItems) {
			this.updatePageAfterDelete(newTotalItems);
		},
	},
	beforeDestroy() {
		this.cleanAreas();
	},
	components: {
		CreateAreaDialog,
		UpdateAreaDialog,
		DeleteAreaDialog,
		ValidateActivity,
		ImportMassiveAreasDialog
	},
};
